import React from "react";
import Banner from "../../utils/banner/banner";
import "../../admission/application-guide/application-guide.css"
import Visiting_Contract_2019 from '../../../handbooks/Visiting_Contract_2019.docx'
import Staff_Appraisal from '../../../handbooks/Academic_Appraisal_2020.docx'
import Junior_Non_Teaching_2019 from '../../../handbooks/Junior_Non_Teaching_2019.doc'
import Senior_Non_Teaching_2019 from '../../../handbooks/Senior_Non_Teaching_2019.docx'
import research_leave_policy from '../../../handbooks/research-leave-policy.docx'
import baze_accounting_handbook from '../../../handbooks/baze_accounting_handbook.pdf'
import baze_anatomy_handbook from '../../../handbooks/baze_anatomy_handbook.pdf'
import baze_architecture_handbook from '../../../handbooks/baze_architecture_handbook.pdf'
import baze_biological_sciences_handbook from '../../../handbooks/baze_biological_sciences_handbook.pdf'
import baze_biotechnology_handbook from '../../../handbooks/baze_biotechnology_handbook.pdf'
import baze_business_management_handbook from '../../../handbooks/baze_business_management_handbook.pdf'
import baze_chemistry_handbook from '../../../handbooks/baze_chemistry_handbook.pdf'
import baze_civil_engineering_handbook from '../../../handbooks/baze_civil_engineering_handbook.pdf'
import baze_computer_engineering_handbook from '../../../handbooks/baze_computer_engineering_handbook.pdf'
import mechatronics_engineering_handbook from '../../../handbooks/mechatronics_engineering_handbook.pdf'
import baze_computer_science_handbook from '../../../handbooks/baze_computer_science_handbook.pdf'
import petroleum_gas_engineering_handbook from '../../../handbooks/petroleum_gas_engineering_handbook.pdf'
import baze_economics_handbook from '../../../handbooks/baze_economics_handbook.pdf'
import baze_chemical_engineering_handbook from '../../../handbooks/baze_chemical_engineering_handbook.pdf'
import baze_elect_elect_handbook from '../../../handbooks/baze_electrical_and_electronic_engineering_handbook.pdf'
import baze_human_physiology_handbook from '../../../handbooks/baze_human_physiology_handbook.pdf'
import baze_international_relations_and_diplomacy_handbook from '../../../handbooks/baze_international_relations_and_diplomacy_handbook.pdf'
import baze_mechanical_engineering_handbook from '../../../handbooks/baze_mechanical_engineering_handbook.pdf'
import baze_physiology_handbook from '../../../handbooks/baze_physiology_handbook.pdf'
import baze_political_science_handbook from '../../../handbooks/baze_political_science_handbook.pdf'
import baze_public_administration_handbook from '../../../handbooks/baze_public_administration_handbook.pdf'
import baze_quantity_surveying_handbook from '../../../handbooks/baze_quantity_surveying_handbook.pdf'
import baze_students_handbook from '../../../handbooks/baze_students_handbook.pdf'
import baze_telecommunication_engineering_handbook from '../../../handbooks/baze_telecommunication_engineering_handbook.pdf'
import THE_BAZE_UNIVERSITY_ANTHEM_SCORE_AND_LYRICS from '../../../handbooks/THE_BAZE_UNIVERSITY_ANTHEM_SCORE_AND_LYRICS.docx'
import BioChemistryHandBook from '../../../handbooks/Biochemistry_Student_Handbook.docx'
import HowToConnectToNetwork from '../../../handbooks/HOW_TO_CONNECT_TO_THE_NETWORK.pdf'
import STAFF_PORTAL_MANUAL from '../../../handbooks/STAFF_PORTAL_MANUAL.pdf'
import drugabuse from '../../../handbooks/drugabuse.pptx'
import DrugUse from '../../../handbooks/DrugUse.pptx'
import baze_law_handbook from '../../../handbooks/baze_law_handbook.pdf'
import handbooks from './handbooks.jpg'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { dynamicSort, toTitleCase } from "../../../resources/constants";




const DocumentsHandbook = () => {
    const cols = [
        {
            name: "Document Title",
            selector: row => `${row.title}`,
            sortable: true,
            width: '80%'
        },
        {
            name: "Download Link",
            cell: (row) => (
                <div>
                    {
                        row.file !== "" ?
                            <a target={"_blank"} href={row.file}>
                                Download Link
                            </a>
                            :
                            ''
                    }
                </div>
            ),
        }
    ];

    const dt__ = [
        { title: toTitleCase('STAFF PROFILE, RESEARCH AND QUALIFICATION UPDATE MANUAL'), file: 'https://bazeuniversity.edu.ng/download/STAFF%20PROFILE%20UPDATE%20MANUAL.pdf' },
        { title: 'CA Upload Manual', file: 'https://bazeuniversity.edu.ng/download/CA%20UPLOAD%20MANUAL.pdf' },
        { title: 'Plagiarism (Turnitin)', file: 'https://drive.google.com/file/d/1v99ckmc0fRUk1o8gSLSmdAIF18eaR0Sg/view' },
        { title: toTitleCase('GOOGLE CLASSROOM'), file: 'https://drive.google.com/file/d/1CZUBX6cfewVIzX6aVwShvTHUbxqXMsDe/view' },
        // { title: toTitleCase('GOOGLE DRIVE'), file: '' },
        { title: toTitleCase('STAFF PORTAL'), file: STAFF_PORTAL_MANUAL },
        { title: toTitleCase('STUDENT PORTAL'), file: 'https://docs.google.com/document/d/1ZKxT9QWnqQq6w1XpvBZT46cSDrpEXg8S/edit?usp=sharing&ouid=100026532811043483986&rtpof=true&sd=true' },
        { title: toTitleCase('STUDENTS USER MANUAL'), file: 'https://drive.google.com/drive/folders/1zLWWKgp3GwHcsT3lQOBzWtnEfk_tqINi' },
        { title: toTitleCase('HOW TO CONNECT TO THE NETWORK'), file: HowToConnectToNetwork },
        { title: 'ITR & I Office Induction Slides', file: 'https://drive.google.com/file/d/1g-eyYhByZkZDlg7kMCJ2-TsSUlEhksl6/view' },
        { title: toTitleCase('ACADEMIC INTEGRITY'), file: 'https://drive.google.com/file/d/1ZFtD-riQ3cbWBL47LhCzRbq0abV0g5gz/view' },
        { title: toTitleCase('PAYMENT SYSTEM MANUAL'), file: 'https://drive.google.com/file/d/1saTVCh0ePuxQFwRd2irDTVUUgA9wjbGT/view' },
        { title: toTitleCase('ATTENDANCE PARTICIPATION AND EXAMINATION ELIGIBILITY GUIDELINES'), file: 'https://drive.google.com/file/d/1_8uT4qgbJuGWgmDbzH2zkp9ltluvBmCs/view?usp=sharing'}

    ]

    const dt_ = [
        { title: 'Visiting/Part Time Academic Staff Appraisal Form', file: Visiting_Contract_2019 },
        { title: 'Academic Staff Appraisal Form (Full Time & Contract)', file: Staff_Appraisal },
        { title: 'Senior Non-Teaching Staff Appraisal', file: Junior_Non_Teaching_2019 },
        { title: 'Research Leave Policy Document', file: research_leave_policy },
        { title: 'Condition Of Service', file: '' },
        { title: 'Staff Regulation', file: 'https://drive.google.com/file/d/1jXCNJiRZF6xOUgyZMmCh51QesQ9gSmTD/view' }
    ]

    const dt = [
        { title: 'Student Handbook', file: baze_students_handbook },
        { title: 'Accounting Handbook', file: baze_accounting_handbook },
        { title: 'Physiology Handbook', file: baze_physiology_handbook },
        { title: 'Political Science Handbook', file: baze_political_science_handbook },
        { title: 'Quantity Surveying Handbook', file: baze_quantity_surveying_handbook },
        { title: 'Anatomy Handbook', file: baze_anatomy_handbook },
        { title: 'Architecture Handbook', file: baze_architecture_handbook },
        { title: 'Biological Sciences Handbook', file: baze_biological_sciences_handbook },
        { title: 'Biotechnology Handbook', file: baze_biotechnology_handbook },
        { title: 'BioChemistry Handbook', file: BioChemistryHandBook },
        { title: 'Business Management Handbook', file: baze_business_management_handbook },
        { title: 'Chemistry Handbook', file: baze_chemistry_handbook },
        { title: 'Civil Engineering Handbook', file: baze_civil_engineering_handbook },
        { title: 'Computer Engineering Handbook', file: baze_computer_engineering_handbook },
        { title: 'Economics Handbook', file: baze_economics_handbook },
        { title: 'Electrical and Electronic Engineering Handbook', file: baze_elect_elect_handbook },
        { title: 'Estate Management Handbook', file: "" },
        { title: 'Goverment Public Administration Handbook', file: baze_public_administration_handbook },
        { title: 'Human Physiology Handbook', file: baze_human_physiology_handbook },
        { title: 'International Relations and Diplomacy Handbook', file: baze_international_relations_and_diplomacy_handbook },
        { title: 'Law Handbook', file: baze_law_handbook },
        { title: 'Mechanical Engineering Handbook', file: baze_mechanical_engineering_handbook },
        { title: 'Petroleum and Gas Engineering Handbook', file: petroleum_gas_engineering_handbook},
        { title: 'Telecommunications Engineering Handbook', file: baze_telecommunication_engineering_handbook },
        { title: 'Chemical Engineering Handbook', file: baze_chemical_engineering_handbook },
        { title: 'Mechatronics Engineering Handbook', file: mechatronics_engineering_handbook },
    ]

    function compare(a, b) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    const columns = cols;
    const data = dt.sort(dynamicSort("title"));
    const tableData = {
        columns,
        data,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search',
    };

    const columns_ = cols;
    const data_ = dt_.sort(dynamicSort("title"));
    const tableData_ = {
        columns: columns_,
        data: data_,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search',
    };

    const columns__ = cols;
    const data__ = dt__.sort(dynamicSort("title"));
    const tableData__ = {
        columns: columns__,
        data: data__,
        print: false,
        exportHeaders: true,
        filterPlaceholder: 'Search',
    };

    return <>
        <Banner image={handbooks} title="Documents and Handbooks" caption="" />

        <div className="container-fluid layout col1" style={{ margin: "10px" }}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="layout-blocks-ucws-checklist container-fluid roundable block checklist">
                        <div className="col-lg-12">
                            <div className="row">
                                <br />
                                <div className="col-md-9">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h3>Human Resources</h3>
                                        </div>
                                    </div>

                                    <DataTableExtensions id="tt"
                                        {...tableData_}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination={false}
                                            highlightOnHover />
                                    </DataTableExtensions>
                                    <br /><br />
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <h3>Course Handbooks</h3>
                                        </div>
                                    </div>
                                    <DataTableExtensions
                                        {...tableData}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination={false}
                                            highlightOnHover />
                                    </DataTableExtensions>

                                    <br /><br />
                                    {/*<div className="row header">*/}
                                    {/*    <div className="col-sm-12">*/}
                                    {/*        <h3>IT, Research and Innovation Office</h3>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <DataTableExtensions
                                        {...tableData__}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination={false}
                                            highlightOnHover />
                                    </DataTableExtensions>

                                </div>
                                <div className="col-md-3">
                                    <div style={{ background: '#f4f4f4', padding: '8px', borderRadius: '5px' }}>
                                        <h4>Couselling Unit</h4>
                                        <ul style={{ listStyle: 'circle' }}>
                                            <li><a href={drugabuse}>Substance Abuse & Alcoholism Among Students</a></li>
                                            <li><a href={DrugUse}>Student Support Unit</a></li>
                                        </ul>
                                        <hr />
                                        <h4>Directorate of Strategy & Special Duties</h4>
                                        <ul style={{ listStyle: 'circle' }}>
                                            <li><a href={THE_BAZE_UNIVERSITY_ANTHEM_SCORE_AND_LYRICS}>Baze University Anthem</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="layout-blocks-ucws-divider container-fluid roundable block divider">
                        <div className="row">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <hr className="solid light-grey" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div className="container-fluid layout col1">
            <div className="row">
                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-info-shim container-fluid roundable block info-shim">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="more-info-line">
                                            <div className="more-info-icon">
                                                <span className="icon icomoon ucws-info-shim-info light-grey"
                                                    aria-hidden="true"></span> Did you know Baze University Admission system is always open?
                                                <a href="https://portal.bazeuniversity.edu.ng/admission"
                                                    target="_blank" rel="noopener"> Apply here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>
}

export default DocumentsHandbook;
